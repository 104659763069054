import { APIFilter } from '@/utils/api'
import { TABLA } from '@/utils/consts'

export default {
  async selectOrdenTrabajo (Vue, idordenTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idorden_trabajo', idordenTrabajo)
      .addGT('estado', 0)
      .addGT('idest_orden_trabajo', 0)
    const resp = await Vue.$api.call('ordenTrabajo.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails (Vue, idordenTrabajo, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    apiCalls.push(Vue.$online.fichero.ApiCallSelectCount(idordenTrabajo, TABLA.orden_trabajo.idtabla, esTecnico, esComercial, esVigilante))
    const apiFilterIdorden = new APIFilter()
      .addExact('idorden_trabajo', idordenTrabajo)
      .addGT('estado', 0)
    apiCalls.push({
      name: 'selectCountLordenTrabajo',
      method: 'lordenTrabajo.select',
      params: { filter: apiFilterIdorden },
    })
    apiCalls.push({
      name: 'selectLordenTrabajoSubsis',
      method: 'lordenTrabajo.selectSubsis',
      params: { filter: apiFilterIdorden },
    })
    apiCalls.push({
      name: 'selectOrdenTrabajoMatsistSubsis',
      method: 'ordenTrabajoMatsist.selectSubsis',
      params: { filter: apiFilterIdorden },
    })
    apiCalls.push({
      name: 'selectParteTrabajo',
      method: 'parteTrabajo.select',
      params: { filter: apiFilterIdorden, wrapper: 'count' },
    })
    apiCalls.push({
      name: 'selectOperacioncrm',
      method: 'operacioncrm.select',
      params: { filter: apiFilterIdorden, wrapper: 'count' },
    })
    const apiFilterNota = Vue.$online.nota.buildApiFilterVisualizacion(idordenTrabajo, TABLA.orden_trabajo.idtabla, esTecnico, esComercial, esVigilante)
    apiCalls.push({
      name: 'selectNota',
      method: 'nota.select',
      params: { filter: apiFilterNota, wrapper: 'count' },
    })
    const apiFilterParteTrabajoTecnico = new APIFilter()
      .addExact('idorden_trabajo', idordenTrabajo)
    apiCalls.push({
      name: 'selectCountParteTrabajoTecnico',
      method: 'parteTrabajoTecnico.select',
      params: { filter: apiFilterParteTrabajoTecnico },
    })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectParteDeOrdenTrabajo(Vue, idordenTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.orden_trabajo)
      .innerJoin(
        tables.parte_trabajo,
        tables.orden_trabajo.idorden_trabajo.eq(tables.parte_trabajo.idorden_trabajo)
      )
      .where(
        tables.orden_trabajo.idorden_trabajo.eq(idordenTrabajo),
      )
      .exec()
    )[0]
  },
}
